import React from "react"
import Layout from "wildComponents/Layout"
import { Seo } from "wildComponents/seo/components"
import { graphql } from "gatsby"
import Hero from "../sections/page-specific/about/Hero"
import IgnitingIdeas from "../sections/page-specific/about/IgnitingIdeas"
import Mission from "../sections/page-specific/about/Mission"
import WhyDuqe from "../sections/page-specific/about/WhyDuqe"
import Perks from "../sections/page-specific/about/Perks"
import DarkGroup from "../components/common/DarkGroup"

export const Head = ({ location, data, pageContext }) => (
  <Seo ctx={pageContext} data={data} location={location} />
)

function About() {
  return (
    <Layout>
      <DarkGroup>
        <Hero />
        <IgnitingIdeas />
        <Mission />
      </DarkGroup>
      <WhyDuqe />
      <Perks />
    </Layout>
  )
}

export default About

export const pageQuery = graphql`
  query ($id: String!) {
    wpPage(id: { eq: $id }) {
      title
    }
  }
`
