import React from "react"

import { Link } from "gatsby"

import Section from "wildComponents/Section"
import YellowHeading from "../../../components/common/YellowHeading"
import {
  AspectRatio,
  Box,
  Button,
  Heading,
  Image,
  Stack,
  Text,
  useTheme,
  VStack,
} from "@chakra-ui/react"

function WhyDuqe() {
  const { variables } = useTheme()
  const { containerPaddingY, componentSpacing } = variables

  return (
    <Section
      className="light"
      containerStyles={{ py: 0, pt: containerPaddingY }}
    >
      <Stack
        direction={["column", "column", "row"]}
        spacing={[12, 12, 16, 20, 24, 32, 40]}
      >
        <Box flex="1" position="relative">
          <Heading
            className="jumbo"
            position="absolute"
            right="5%"
            top="-3%"
            zIndex="1"
          >
            <YellowHeading>Why</YellowHeading>
            <br /> Duqe?
          </Heading>
          <AspectRatio w="full" ratio={864 / 927} data-speed="0.9" zIndex="-1">
            <Image
              src="https://res.cloudinary.com/wild-creative/image/upload/v1653637123/DUQE/Vectors/QImage_ypouy8.png"
              alt="Why Duqe"
              w="100%"
            />
          </AspectRatio>
        </Box>
        <VStack spacing={componentSpacing} align="flex-start" flex="1">
          <Heading>
            REACH YOUR DREAMS. <br />
            <YellowHeading> CREATE YOUR LEGACY.</YellowHeading>
          </Heading>
          <Text>
            Equipped with a modern business centre that offers all-in-one
            support, DUQE is the ideal launchpad for both the budding
            entrepreneurs and seasoned business owners.
          </Text>
          <Link to="/business-activities/">
            <Button>See All Activities</Button>
          </Link>
        </VStack>
      </Stack>
    </Section>
  )
}

export default WhyDuqe
